<template>
	<div>
		<template v-if="!imodel.loaded"><v-progress-linear indeterminate /></template>
		<template v-else>
			<div v-show="!isEdit">
				<Search v-if="!hideSearch" @filter="filter = $event" :defaults="defaultSearch" />
				<v-toolbar flat>
					<v-toolbar-title>{{ title || imodel.Title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn @click="onAdd" dark color="primary"><v-icon left>mdi-plus</v-icon> Нов запис</v-btn>
				</v-toolbar>
				<List
					v-bind="propsList"
					ref="list"
					:headers="headers"
					:filter="filter"
					@edit="onEdit"
				>
					<template v-for="slot of listSlots" v-slot:[slot]="scope"
						><slot :name="'list.'+slot" v-bind="scope"
					/></template>
				</List>
			</div>
			<edit-column
				v-bind="propsEdit"
				:id="id"
				v-if="isEdit"
				@saveclose="onSaveClose"
				@save="onSave"
				@close="onEditCancel"
				ref="EditForm"
			>
				<template v-for="slot of editSlots" v-slot:[slot]="scope"
					><slot :name="'edit.'+slot" v-bind="scope"
				/></template>
			</edit-column>
		</template>
	</div>
</template>

<script>
import List from "@/ittijs/List";
import Search from "@/ittijs/Search";
import IndexMixin from "@/ittijs/IndexMixin";
import IModelMixin from "@/ittijs/IModelMixin";
import EditColumn from "@/ittijs/EditColumn";

export default {

	props: {
		'title': String,
		'headers': String,
		'hideSearch': Boolean,
		'propsList': Object,
		'propsEdit': Object,
	},

	mixins: [
		IndexMixin,
		IModelMixin,
	],

	data: () => ({
		defaultSearch: {
			rules:[],
			groups:{
				g1:{rules:{}}
			},
		},
		filter: {},
	}),

	components: {
		EditColumn,
		Search,
		List,
	},

	computed: {
		// slots for the list control, prefixed with "list."
		listSlots(){
			const prefix = 'list.';
			return Object.keys(this.$scopedSlots)
				.filter(slot => slot.startsWith(prefix))
				.map(slot => slot.substring(prefix.length));
		},
		// slots for the edit control, prefixed with "edit."
		editSlots(){
			const prefix = 'edit.';
			return Object.keys(this.$scopedSlots)
				.filter(slot => slot.startsWith(prefix))
				.map(slot => slot.substring(prefix.length));
		},
	},

}
</script>