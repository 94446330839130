<template>
	<index
		headers="ord,title,type,action,price_service,price_product,is_archived"
		:props-edit="{leftColWidth:150}"
	>
		<template #edit.after-row-right.is_archived="{row}">
			<v-alert type="info" dense text v-if="parseInt(row.is_archived) > 0">
				Архивираните услуги са недостъпни при заявяване на нови посещения
			</v-alert>
		</template>
	</index>
</template>

<script>
import index from '@/ittijs/IndexDefault.vue';
import Model from './Model';

const imodel = new Model;

export default {

	components: {
		index,
	},

	provide: function () {
		return {
			imodel,
		};
	},

}
</script>